<template>
  <div
    v-editable="blok"
    class="CoverBlock"
    :class="{
      'heroIfFirst': blok.heroIfFirst,
    }"
  >
    <component
      :is="$sbLinkCheck(blok.link) ? NuxtLink:'div'"
      :to="$sbLinkFix(blok.link)"
      :target="$sbLinkTarget(blok.link)"
    >
      <div
        :style="`background-color: ${blok.backgroundColor.color}`"
        class="flex flex-col gap-24 desk:flex-row-reverse p-32 desk:p-64 w-full relative desk:aspect-[16/7]"
      >
        <div
          v-if="blok.imageOrBackgroundVideo && blok.imageOrBackgroundVideo.filename"
          class="w-full h-full absolute top-0 left-0"
        >
          <video
            v-if="checkIfVideoFile(blok.imageOrBackgroundVideo.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.imageOrBackgroundVideo.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.imageOrBackgroundVideo && blok.imageOrBackgroundVideo.filename"
            preset="standard"
            sizes="sm:100vw desk:1024px deskStd:1372px deskLarge:1600px"
            class="h-full w-full object-cover"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            :src="blok.imageOrBackgroundVideo.filename"
            :alt="blok.imageOrBackgroundVideo.alt"
          />
        </div>
        <div
          v-if="blok.desktopImageOrBackgroundVideo && blok.desktopImageOrBackgroundVideo.filename"
          class="hidden desk:block w-full h-full absolute top-0 left-0"
        >
          <video
            v-if="checkIfVideoFile(blok.desktopImageOrBackgroundVideo.filename)"
            width="w-full"
            height="h-full"
            class="w-full h-full object-cover"
            loop
            muted
            autoplay
            playsinline
          >
            <source :src="blok.desktopImageOrBackgroundVideo.filename" type="video/mp4">
          </video>
          <nuxt-img
            v-else-if="blok.desktopImageOrBackgroundVideo && blok.desktopImageOrBackgroundVideo.filename"
            preset="standard"
            sizes="sm:100vw desk:1260px"
            class="h-full w-full object-cover"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            :src="blok.desktopImageOrBackgroundVideo.filename"
            :alt="blok.desktopImageOrBackgroundVideo.alt"
          />
        </div>
        <div
          class="relative w-full h-full aspect-square desk:aspect-[5/4]"
          :class="{
            'desk:aspect-[16/8]': blok.design === 'oneColumn',
          }"
        >
          <nuxt-img
            v-if="blok.desktopImage && blok.desktopImage.filename"
            preset="standard"
            sizes="sm:100vw desk:1024px deskStd:1372px deskLarge:1600px"
            class="hidden desk:block h-full w-full object-cover"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            :src="blok.desktopImage.filename"
            :alt="blok.desktopImage.alt"
          />
          <!-- image on top -->
          <nuxt-img
            v-if="blok.image && blok.image.filename"
            preset="standard"
            :sizes="spashImageSizes"
            class="h-full w-full object-cover"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            :class="{
              'desk:hidden' : blok.desktopImage && blok.desktopImage.filename,
            }"
            :src="blok.image.filename"
            :alt="blok.image.alt"
          />
          <div
            v-if="showTapes"
            class="TapeWrapper w-full h-full absolute top-0 left-0 type-headline-xxs desk:type-headline-xs"
            :class="{
              'text-darkest': isLight(blok.tapeBackgroundColor.color),
              'text-lightest': !isLight(blok.tapeBackgroundColor.color)
            }"
          >
            <div
              :style="{backgroundColor : blok.tapeBackgroundColor.color}"
              class="TapeLeft flex items-center justify-center pl-[3px] w-24 desk:w-32 h-full absolute top-0 bottom-0 left-0 overflow-hidden"
            >
              <div class="flex gap-8 desk:gap-12 whitespace-nowrap -rotate-90">
                <span
                  v-for="n in 34"
                  :key="n"
                  class="odd:font-normal"
                >
                  {{ blok.tapeText }}
                </span>
              </div>
            </div>
            <div
              :style="{backgroundColor : blok.tapeBackgroundColor.color}"
              class="TapeBottom flex items-center justify-center pb-[3px] w-full h-24 desk:h-32 absolute right-0 bottom-0 left-0 overflow-hidden"
            >
              <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-180">
                <span
                  v-for="n in 34"
                  :key="n"
                  class="odd:font-normal"
                >
                  {{ blok.tapeText }}
                </span>
              </div>
            </div>
            <div
              :style="{backgroundColor : blok.tapeBackgroundColor.color}"
              class="TapeRight flex items-center justify-center pr-[3px] w-24 desk:w-32 h-full absolute top-0 right-0 bottom-0 overflow-hidden"
            >
              <div class="flex gap-8 desk:gap-12 whitespace-nowrap rotate-90">
                <span
                  v-for="n in 34"
                  :key="n"
                  class="odd:font-normal"
                >
                  {{ blok.tapeText }}
                </span>
              </div>
            </div>
            <div
              :style="{backgroundColor : blok.tapeBackgroundColor.color}"
              class="TapeTop flex items-center justify-center pt-[3px] w-full h-24 desk:h-32 absolute top-0 left-0 overflow-hidden"
            >
              <div class="flex gap-8 desk:gap-12 whitespace-nowrap">
                <span
                  v-for="n in 34"
                  :key="n"
                  class="odd:font-normal"
                >
                  {{ blok.tapeText }}
                </span>
              </div>
            </div>
          </div>
          <nuxt-img
            v-if="blok.splashImage && blok.splashImage.filename"
            preset="standard"
            sizes="sm:100vw desk:1260px"
            class="spin absolute -top-16 -left-16 desk:-top-24 desk:-left-24 w-64 h-64"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            :class="{
              'desk:w-112 desk:h-112': blok.design === 'oneColumn',
              'desk:w-96 desk:h-96': blok.design !== 'oneColumn',
            }"
            :src="blok.splashImage.filename"
            :alt="blok.splashImage.alt"
          />
          <div
            v-if="blok.splashDiscountText || blok.splashDiscountNumber"
            class="absolute -bottom-16 -right-16 w-64 h-64 bg-darkest rounded-full text-lightest
          flex flex-col items-center justify-center"
            :class="{
              'desk:w-112 desk:h-112': blok.design === 'oneColumn',
              'desk:w-96 desk:h-96': blok.design !== 'oneColumn',
            }"
          >
            <div
              v-if="blok.splashDiscountText"
              class="-mb-2 mt-2 type-headline-xxs"
              :class="{
                'desk:type-headline-sm': blok.design === 'oneColumn',
                'desk:type-headline-xs': blok.design !== 'oneColumn',
              }"
            >
              {{ blok.splashDiscountText }}
            </div>
            <div
              v-if="blok.splashDiscountNumber"
              class="-mb-2 mt-2 type-headline-lg"
              :class="{
                'desk:type-headline-2xl': blok.design === 'oneColumn',
                'desk:type-headline-xl': blok.design !== 'oneColumn',
              }"
            >
              {{ blok.splashDiscountNumber }}%
            </div>
          </div>
          <nuxt-img
            v-if="blok.splashDiscountImage && blok.splashDiscountImage.filename"
            preset="standard"
            loading="lazy"
            sizes="sm:100vw desk:1260px"
            :preload="isTop"
            :loading="isTop ? 'eager':'lazy'"
            class="absolute -bottom-16 -right-16 w-64 h-64"
            :class="{
              'desk:w-112 desk:h-112': blok.design === 'oneColumn',
              'desk:w-96 desk:h-96': blok.design !== 'oneColumn',
            }"
            :src="blok.splashDiscountImage.filename"
            :alt="blok.splashDiscountImage.alt"
          />
        </div>
        <div
          class="flex flex-col w-full"
          :class="{
            'relative': blok.design === 'default',
            'justify-center absolute top-0 left-0 h-full': blok.design === 'oneColumn',
            'desk:justify-center': blok.design === 'default',
            'justify-between relative': blok.design === 'displayLogo',
          }"
        >
          <img
            v-if="blok.design === 'displayLogo'"
            alt=""
            src="/logos/bywe_stacked-logo.svg"
            class="w-1/2 max-w-[580px] hidden desk:block"
          >
          <div
            class="flex flex-col items-center desk:items-start"
            :class="{
              'desk:items-center': blok.design === 'oneColumn',
              'desk:items-start': blok.design !== 'oneColumn',
            }"
          >
            <div
              class="type-headline-sm underline decoration-[#C7C6C2] mb-12"
              :class="{
                'text-lightest': blok.taglineAndHeadlineInvertedText,
              }"
            >
              {{ blok.tagline }}
            </div>
            <div
              class="type-headline-xl desk:type-headline-4xl mb-4"
              :class="{
                'text-lightest': blok.taglineAndHeadlineInvertedText,
              }"
            >
              {{ blok.headline }}
            </div>
            <div class="wysiwyg mb-16">
              <ExtendedWysiwyg :content="blok.content" />
            </div>
            <div
              v-if="blok.buttonText"
              class="btn btn--primary"
            >
              {{ blok.buttonText }}
            </div>
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import {
  StoryBlokPropBase,
  StoryBlokAsset,
  StoryBlokLink,
  StoryBlokColorpicker,
  StoryBlokVisualApperance
} from '~/constants/types/storyblok';
import { ISbRichtext } from 'storyblok-js-client';
import { checkIfVideoFile } from '~/util/imageUtil';
import { isLight } from '~/util/colorUtil';
const NuxtLink = resolveComponent('NuxtLink');

type SplashSelector = StoryBlokPropBase & {
  splash: string;
  splashImage: StoryBlokAsset;
}

type Props = StoryBlokPropBase & {
  tagline: string;
  headline: string;
  taglineAndHeadlineInvertedText: boolean;
  content: ISbRichtext;
  buttonText: string;
  link: StoryBlokLink;
  imageOrBackgroundVideo: StoryBlokAsset;
  desktopImageOrBackgroundVideo: StoryBlokAsset;
  image: StoryBlokAsset;
  desktopImage: StoryBlokAsset;
  backgroundColor: StoryBlokColorpicker;
  design: 'default' | 'oneColumn' | 'displayLogo';
  topLeftSplash: SplashSelector[];
  bottomRightSplash: SplashSelector[];
  visualAppearance: StoryBlokVisualApperance;
  splashImage: StoryBlokAsset,
  splashDiscountImage: StoryBlokAsset,
  splashDiscountNumber: number,
  splashDiscountText: string,
  tapeBackgroundColor: StoryBlokColorpicker;
  tapeText: string;
  heroIfFirst: boolean;
}

const props = defineProps<{
  blok: Props,
  placement?: number,
}>();

const showTapes = computed(()=> {
  if (props.blok.splashImage &&  props.blok.splashImage.filename && props.blok.splashImage.filename !== '') {
    return false;
  }
  if (props.blok.splashDiscountImage &&  props.blok.splashDiscountImage.filename && props.blok.splashDiscountImage.filename !== '') {
    return false;
  }
  return props.blok.tapeText && props.blok.tapeBackgroundColor.color
      && props.blok.image && props.blok.image.filename
      && !props.blok.splashDiscountNumber && !props.blok.splashDiscountText;

});

const spashImageSizes = computed(()=> {
  return props.blok.design === 'oneColumn' ?
    'sm:100vw desk:896px deskStd:1244px deskLarge:1472px':
    'sm:100vw desk:436px deskStd:610px deskLarge:724px';
});

const isTop = computed(()=> {
  return (props.placement === 0);
});

</script>

<style scoped lang="postcss">
.spin {
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
